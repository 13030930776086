var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-row",
        { staticClass: "mb-lg-0 mb-3" },
        [
          _c(
            "b-col",
            { attrs: { cols: "12", lg: "6" } },
            [
              _c("b-row", { staticClass: "px-3 py-0" }, [
                _c("label", { staticClass: "pt-1 pr-1 pb-1 pl-0" }, [
                  _vm._v(_vm._s(_vm.$t("dataview.field.sharing"))),
                ]),
              ]),
              _c(
                "b-row",
                {
                  staticClass: "pt-0 pr-3 pl-3",
                  class: [_vm.showSharingMemberListError ? "pb-0" : "pb-3"],
                },
                [
                  _c("b-form-radio-group", {
                    staticClass: "radio-group-class",
                    attrs: {
                      disabled: _vm.readOnly,
                      options: _vm.sharingOptions,
                    },
                    model: {
                      value: _vm.visibility,
                      callback: function ($$v) {
                        _vm.visibility = $$v
                      },
                      expression: "visibility",
                    },
                  }),
                  _c(
                    "b-button",
                    {
                      staticClass: "d-inline-block",
                      attrs: {
                        disabled:
                          _vm.sharedVisibility === "public" || _vm.readOnly,
                        size: "sm",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.toggleSharingMembers()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.members")))]
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-form-invalid-feedback",
                        {
                          staticClass: "alert-danger form-field-alert",
                          class: { "d-block": _vm.showSharingMemberListError },
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: ["far", "circle-exclamation"] },
                          }),
                          _vm._v(
                            "  " +
                              _vm._s(
                                _vm.validationErrors.first("sharingMemberList")
                              ) +
                              " "
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "12", lg: "6" } },
            [
              _c(
                "label",
                { staticClass: "d-block pt-1 pr-1 pb-1 pl-0 pl-lg-1" },
                [_vm._v(_vm._s(_vm.$t("dataview.field.editing_permissions")))]
              ),
              _c(
                "b-button",
                {
                  attrs: { size: "sm", disabled: _vm.readOnly },
                  on: {
                    click: function ($event) {
                      return _vm.toggleEditors()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("button.members")))]
              ),
              _c(
                "b-form-invalid-feedback",
                {
                  staticClass: "alert-danger form-field-alert",
                  class: { "d-block": _vm.showEditorListError },
                },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: ["far", "circle-exclamation"] },
                  }),
                  _vm._v(
                    "  " +
                      _vm._s(_vm.validationErrors.first("editorList")) +
                      " "
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      !_vm.readOnly
        ? [
            _vm.showSharingMembers
              ? _c("MembersModal", {
                  attrs: {
                    show: _vm.showSharingMembers,
                    members: _vm.sharingMemberList,
                    title: _vm.$t("dataview.select_members_title"),
                  },
                  on: {
                    "update:show": function ($event) {
                      _vm.showSharingMembers = $event
                    },
                    success: _vm.sharingMembersOk,
                  },
                })
              : _vm._e(),
            _vm.showEditors
              ? _c("MembersModal", {
                  attrs: {
                    show: _vm.showEditors,
                    members: _vm.editorList,
                    title: _vm.$t("dataview.select_members_editing_title"),
                  },
                  on: {
                    "update:show": function ($event) {
                      _vm.showEditors = $event
                    },
                    success: _vm.editorsOk,
                  },
                })
              : _vm._e(),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }