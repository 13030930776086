<template>
  <div>
    <b-row class="mb-lg-0 mb-3">
      <b-col cols="12" lg="6">
        <b-row class="px-3 py-0">
          <label class="pt-1 pr-1 pb-1 pl-0">{{ $t('dataview.field.sharing') }}</label>
        </b-row>
        <b-row class="pt-0 pr-3 pl-3" :class="[ showSharingMemberListError? 'pb-0': 'pb-3']">
            <b-form-radio-group :disabled="readOnly"
              class="radio-group-class"
              v-model="visibility"
              :options="sharingOptions">
            </b-form-radio-group>
            <b-button :disabled="sharedVisibility === 'public' || readOnly" class="d-inline-block" size="sm" @click="toggleSharingMembers()">{{ $t('button.members') }}</b-button>
        </b-row>
        <b-row>
          <b-col>
            <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showSharingMemberListError }">
              <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ validationErrors.first('sharingMemberList') }}
            </b-form-invalid-feedback>
          </b-col>
        </b-row>
        
      </b-col>
      <b-col cols="12" lg="6">
        <label class="d-block pt-1 pr-1 pb-1 pl-0 pl-lg-1">{{ $t('dataview.field.editing_permissions') }}</label>
        <b-button size="sm" :disabled="readOnly" @click="toggleEditors()">{{ $t('button.members') }}</b-button>
        <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showEditorListError }">
          <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ validationErrors.first('editorList') }}
        </b-form-invalid-feedback>
      </b-col>
    </b-row>

    <template v-if="!readOnly">
      <MembersModal v-if="showSharingMembers" :show.sync="showSharingMembers" :members="sharingMemberList" :title="$t('dataview.select_members_title')" @success="sharingMembersOk"/>
      <MembersModal v-if="showEditors" :show.sync="showEditors" :members="editorList" :title="$t('dataview.select_members_editing_title')" @success="editorsOk"/>    
    </template>
  </div>
</template>

<script>

import { userService } from '@/services';
import { objectClone } from '@/helpers';
import { fieldValidateUtil } from '@/script/helper-field-validate';

export default {
  name: "ProfileShareEdit",
  components: {
    MembersModal:  () => import('@/components/Profile/ProfileShareEditMembersModal')
  },
  props: {
    readOnly: {
      type: Boolean,
      default: false
    },
    sharedVisibility: {
      type: String,
      default: 'public'
    },
    sharingMembers: {
      type: Array,
      required: true
    },
    editors: {
      type: Array,
      required: true
    },
    propReady: {
      type: Boolean,
      required: true
    },
    validationErrors: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showSharingMembers: false,
      sharingMemberList: [],
      showEditors: false,
      editorList: [],
      visibility: 'public',
    }
  },
  computed: {
    sharingOptions() {
      return [
        {text: 'Public', value: 'public' },
        {text: 'Private', value: 'private' }
      ]
    },
    showSharingMemberListError() {
      return fieldValidateUtil.hasError(this.validationErrors, 'sharingMemberList');
    },
    showEditorListError() {
      return fieldValidateUtil.hasError(this.validationErrors, 'editorList');
    },
  },
  watch: {
    visibility(newValue) {
      if (this.sharedVisibility != newValue) {
        this.$emit('update:sharedVisibility', newValue);
      }
    },
    propReady(newValue) {
      if (newValue != true) {
        return;
      }
      this.visibility = this.sharedVisibility;

      this.sharingMemberList.splice(0, this.sharingMemberList.length);
      this.editorList.splice(0, this.editorList.length);

      const uuIds = objectClone(this.sharingMembers);
      for (const id of this.editors) {
        if (!uuIds.includes(id)) {
          uuIds.push(id);
        }
      }
      userService.getNameByUuIds(uuIds).then(list => {
        for (const m of list) {
          if (this.sharingMembers.includes(m.uuId)) {
            this.sharingMemberList.push(m);
          }
          if (this.editors.includes(m.uuId)) {
            this.editorList.push(m);
          }
        }

        //Data correction: Make sure editors are subset of sharing members.
        for (const m of this.editorList) {
          if (this.sharingMemberList.findIndex(i => i.uuId == m.uuId) == -1) {
            this.sharingMemberList.push(m);
          }
        }
      });

      this.$emit('update:propReady', false);
    },
    editorList(newValue) {
      if (this.propReady) {
        //When propReady is true, the value changed is triggered by data initialization. No validation is needed.
        return;
      }
      if (newValue == null || newValue.length == 0) {
        this.validationErrors.add({
          field: 'editorList',
          msg: this.$i18n.t('task.group.error.editing_empty')
        });
      } else {
        this.validationErrors.remove('editorList');
      }
    },
    sharingMemberList(newValue) {
      if (this.propReady) {
        //When propReady is true, the value changed is triggered by data initialization. No validation is needed.
        return;
      }
      if (this.visibility != 'public' && (newValue == null || newValue.length == 0)) {
        this.validationErrors.add({
          field: 'sharingMemberList',
          msg: this.$i18n.t('task.group.error.sharing_empty')
        });
      } else {
        this.validationErrors.remove('sharingMemberList');
      }
    }
  },
  methods: {
    toggleSharingMembers() {
      this.showSharingMembers = true;
    },
    toggleEditors() {
      this.showEditors = true;
    },
    sharingMembersOk(members) {
      this.sharingMemberList.splice(0, this.sharingMemberList.length, ...members);
      //Sync editors: Remove editor member if he/she is no longer one of sharing members.
      const editors = objectClone(this.editorList);
      for (const m of editors) {
        if (this.sharingMemberList.findIndex(i => i.uuId == m.uuId) == -1) {
          this.editorList.splice(this.editorList.findIndex(i => i.uuId == m.uuId), 1);
        }
      }
      this.$emit('update:sharingMembers', this.sharingMemberList.map(i => i.uuId));
      this.$emit('update:editors', this.editorList.map(i => i.uuId));
      this.$nextTick(() => {
        this.$emit('update:propReady', true);
      });
    },
    editorsOk(members) {
      this.editorList.splice(0, this.editorList.length, ...members);
      //Sync sharingMembers: Add editor to sharing members if he/she is not one of sharing members.
      for (const m of this.editorList) {
        if (this.sharingMemberList.findIndex(i => i.uuId == m.uuId) == -1) {
          this.sharingMemberList.push(m);
        }
      }
      this.$emit('update:sharingMembers', this.sharingMemberList.map(i => i.uuId));
      this.$emit('update:editors', this.editorList.map(i => i.uuId));
      this.$nextTick(() => {
        this.$emit('update:propReady', true);
      });
    }
  },
}
</script>

<style>

</style>